
import Vue, {
  computed, defineComponent, nextTick, onMounted, PropType, ref, toRefs, watch,
} from 'vue';
import TrackProgress from '@/components/tracks/TrackProgress';
import TrackViewBannerCompleted from '@/views/App/Course/CourseView/TrackViewBannerCompleted/TrackViewBannerCompleted.vue';
import CourseSidebarStepAtom from './CourseSidebarStepAtom.vue';
import CourseSidebarStepSection from './CourseSidebarStepSection.vue';

import { DateTimeISO8601, UUID } from '@/domains/common';
import { CourseTreeNodeType, ICourseStepState } from '@/domains/course/types';
import { ITree, findBy } from '@/common/tree';
import { isCourseStepAtom } from '@/domains/course/utils/isCourseStepAtom';
import { useI18n } from '@/plugins/vue-i18n';

// TODO: переименовать в TrackSidebar
export default defineComponent({
  name: 'TrackSidebar',

  components: {
    TrackProgress,
    TrackViewBannerCompleted,
    CourseSidebarStepAtom,
    CourseSidebarStepSection,
  },

  props: {
    progress: {
      type: Number,
      required: true,
    },

    deadline: {
      type: String as PropType<DateTimeISO8601 | null>,
      default: null,
    },

    steps: {
      type: Object as PropType<ITree<ICourseStepState>>,
      required: true,
    },

    initialView: {
      type: String as PropType<'sections' | 'section'>,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    currentStep: {
      type: Object as PropType<ICourseStepState>,
      required: true,
    },
  },

  emits: ['click:step', 'step:set'],

  setup(props, { emit }) {
    const { progress, steps, currentStep } = toRefs(props);
    const currentSectionId = ref<UUID | null>(null);
    const setCurrentSectionId = (sectionId: UUID | null) => {
      currentSectionId.value = sectionId;
    };
    const currentSection = computed(() =>
      (currentSectionId.value ? findBy(steps.value, 'id', currentSectionId.value) : null));

    const getRefName = (step: ITree<ICourseStepState>) => {
      return (step.current.isActive ? 'activeStep' : 'step');
    };

    const onCourseStepClick = (stepEntity: ICourseStepState) => {
      if (!stepEntity.id) {
        return;
      }

      if (stepEntity.id === currentStep.value.id) {
        return;
      }

      if (stepEntity.itemType === CourseTreeNodeType.SECTION) {
        setCurrentSectionId(stepEntity.id);
      } else {
        emit('step:set', stepEntity);
      }
    };

    // Скроллим до текущего шага
    const activeStep = ref<InstanceType<typeof Vue>[] | null>(null);
    const currentStepScrollIntoView = async () => {
      if (activeStep.value) {
        await nextTick();
        // Активны и секция и шаг в ней, поэтому сперва проверяем, если ли шаг - activeStep.value[1]
        const { $el } = activeStep.value[1] ?? activeStep.value[0];

        $el.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    };

    // находим в steps id секции, где находится текущий шаг
    const findSectionByCurrentStep = () => {
      const section = steps.value.children.find((step) => {
        if (!step.children.length) return null;
        return step.children.find((st) => {
          if (st.children.length) {
            return st.children.flatMap((s) => s).find((s) => s.current.id === currentStep.value.id);
          }

          return st.current.id === currentStep.value.id;
        });
      });
      return section;
    };

    watch(currentStep, (newVal) => {
      const isCurrentStepInCurrentSection = currentSection.value?.children.some((child) => {
        if (!child.children.length) return child.current.id === newVal.id;
        return child.children.some((c) => c.current.id === newVal.id);
      });

      if (!currentSection.value || !isCurrentStepInCurrentSection) {
        const section = findSectionByCurrentStep();
        setCurrentSectionId(section?.current.id ?? null);
        currentStepScrollIntoView();
      } else {
        currentStepScrollIntoView();
      }
    }, { immediate: true });

    const isCoursePassed = computed(() => progress.value >= 100);

    const bannerComponent = computed(() => {
      if (isCoursePassed.value) {
        return TrackViewBannerCompleted;
      }

      return null;
    });

    const isBannerVisible = computed(() => bannerComponent.value !== null);

    const isStepSection = (step: ICourseStepState) => step.itemType === CourseTreeNodeType.SECTION;

    const { t } = useI18n();

    const stepTooltipText = computed(() => t('TrackSidebar.stepTooltip'));

    onMounted(() => {
      currentStepScrollIntoView();
    });

    return {
      currentSectionId,
      setCurrentSectionId,
      currentSection,
      onCourseStepClick,
      isCoursePassed,
      isBannerVisible,
      bannerComponent,
      isStepSection,
      isCourseStepAtom,
      stepTooltipText,
      activeStep,
      getRefName,
    };
  },
});
