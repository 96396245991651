
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';
import TrackStepIcon from '@/components/tracks/TrackStep/TrackStepIcon';
import { AtomType } from '@/domains/atom';
import { UUID } from '@/domains/common';

export default defineComponent({
  name: 'CourseSidebarStepAtom',

  components: { TrackStepIcon },

  props: {
    id: {
      type: String as PropType<UUID>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    atomType: {
      type: String as PropType<AtomType>,
      required: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isCompleted: {
      type: Boolean,
      required: true,
    },
    isFailed: {
      type: Boolean,
      required: true,
    },
    isWasted: {
      type: Boolean,
      required: true,
    },
    dataTest: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { tooltip, isDisabled } = toRefs(props);

    const hasTooltip = computed(() => tooltip.value && isDisabled.value);

    const onClick = () => {
      emit('click');
    };

    return {
      hasTooltip,
      onClick,
    };
  },
});
