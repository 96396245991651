
import { defineComponent } from 'vue';

import TTPrompt from '@uikit/components/TTPrompt/TTPrompt.vue';
import CourseSectionProgress from '@/components/course/contentTree/CourseSectionProgress/CourseSectionProgress.vue';

export default defineComponent({
  name: 'CourseSidebarStepSection',
  components: {
    TTPrompt,
    CourseSectionProgress,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    totalLessonsCount: {
      type: Number,
      default: 0,
    },
    totalTestsCount: {
      type: Number,
      default: 0,
    },
    progress: {
      type: Number,
      default: 0,
    },
    orderNumber: {
      type: Number,
      default: 1,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(_, { emit }) {
    const onClick = () => {
      emit('click');
    };

    return {
      onClick,
    };
  },
});
